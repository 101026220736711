<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <Person
        v-for="(person, id) in backupPeople"
        v-bind:key="id"
        v-bind:person="person"
        @selected="save"
        @edit="edit"
        :type="'executor_backup'"
      >
      </Person>
      <PersonAddQuad @addPerson="setNewPerson" :brotherSister="true">
      </PersonAddQuad>
      <PersonModal
        v-show="show.personModal"
        :person="selectedPerson"
        :show="show.personModal"
        :initialPerson="initialPerson"
        @close="closePersonModal"
      ></PersonModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="'/executors/check_and_confirm'"
        v-bind:backLink="'/executors/backup_option'"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import Person from '@/common/ui/Person'
import PersonModal from '@/views/pages/PeoplePage/PersonModal'
import PersonAddQuad from '@/common/ui/PersonAddQuad'

import { willStringHelpers } from '@/common/mixins/willStringHelpers'

export default {
  name: 'ExecutorsBackup',
  mixins: [willStringHelpers],
  components: {
    NavQuestion,
    NavQuestionButtons,
    Person,
    PersonModal,
    PersonAddQuad
  },
  computed: {
    heading() {
      return 'Who would you like to name as your backup executor(s)?'
    },
    number() {
      return '5'
    },
    subHeading() {
      return (
        'These are the people who will take charge should ' +
        this.executorsPeopleText +
        ' have passed away before you.'
      )
    },
    executorsPeopleText() {
      var peopleList = this.people.filter(
        (person) => person.executor_sole_primary || person.executor_secondary
      )
      if (peopleList.length > 1)
        return this.listPeople(peopleList, ' and ', true)
      return this.listPeople(peopleList)
    },
    backupPeople() {
      return this.$store.getters.people.filter(
        (person) =>
          !person.executor_primary &&
          !person.under18 &&
          !person.executor_secondary &&
          !person.executor_sole_primary
      )
    },
    people() {
      return this.$store.getters.people
    },
    partner() {
      return this.$store.getters.partner
    },
    forwardTo() {
      return '/executors/check_and_confirm'
    },
    backTo() {
      if (this.partner) return '/executors/primary'
      return '/executors/type_of'
    },
    isValidated() {
      if (this.people.filter((person) => person.executor_backup).length)
        return true
      return false
    }
  },
  data() {
    return {
      loading: false,
      selectedPerson: null,
      initialPerson: null,
      show: {
        personModal: false
      }
    }
  },
  methods: {
    save(person) {
      var copyPerson = Object.assign({}, person)
      copyPerson.executor_backup = !person.executor_backup
      this.$store.commit('personAdd', copyPerson)
    },
    edit(person) {
      this.selectedPerson = person
      this.show.personModal = true
    },
    closePersonModal() {
      this.selectedPerson = null
      this.show.personModal = false
      this.initialPerson = null
    },
    setNewPerson(value) {
      value.push('executor_backup')
      this.initialPerson = value
      this.show.personModal = true
      this.selectedPerson = null
    }
  }
}
</script>
